import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import en from "translations/en"
// import vi from "translations/vi"

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    returnNull: false,
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      // vi: {
      //   translation: vi,
      // },
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  })

export default i18n
