import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, Route, Routes } from "react-router-dom"

const Bot = React.lazy(() => import("pages/Bot"))
const Theme = React.lazy(() => import("pages/Theme"))
const Reports = React.lazy(() => import("pages/Reports"))
const GrowthReport = React.lazy(() => import("pages/Reports/GrowthReport"))

const MainRoutes = () => {
  const { t } = useTranslation()

  return (
    <React.Suspense fallback={<span>{t("loading")}</span>}>
      <Routes>
        <Route path="/" element={<Navigate to="/bot" />} />
        <Route path="/bot" element={<Bot />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/reports/growth" element={<GrowthReport />} />
        <Route path="/theme" element={<Theme />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
