export const SIDEBAR_ITEMS = [
  {
    id: "bot",
    name: "bot",
    icon: "sidebar/bot",
    url: "/bot",
    apiKey: "api::bot",
    controllerKey: "bot",
  },
  {
    id: "report",
    name: "report",
    icon: "sidebar/report",
    url: "/reports",
    // apiKey: "api::term",
    // controllerKey: "term",
  },
]
